import React from 'react'
import MachineCard from './MachineCard'
import m1 from '../assets/machine1.webp';
import m2 from '../assets/machine2.webp';

import m3 from '../assets/cynic170.png';
import m4 from '../assets/GT-2A turningmachine500x500.jpg';
import m5 from '../assets/hmtnh26lathemachine.jpg';
import m6 from '../assets/Lathe(Decore).jpg';
import m7 from '../assets/samrot-transformed.jpeg';
import m8 from '../assets/powerpressmachines-20T.jpg';
import m9 from '../assets/Drill.jpg';
import m10 from '../assets/machine4a.jpg';
import m11 from '../assets/tappingmachine.jpg';
import m12 from '../assets/Grinder.jpg';


import { Carousel, CarouselItem } from 'react-bootstrap';

const Machines = () => {
    const machines = [m3, m4, m5, m6, m7, m8, m9, m10, m11, m12];

    return (<>
        <div className='my-5'>
            <div>
                <h2 className='mb-3 display-6 fw-bold'>Machines We Use</h2>
                <div>
                    <p className='w-75 mx-auto subHeading fs-6 text-start text-body-secondary'>We have 11 PMT Traub Machines, 02 Lathe machines, 4 Baby lathe machines, 01 Milling
                        Machine, 05 drilling machines, 05 Tapping machine, 03 Grinding machine’s , 03 CNC Turning
                        Center, 01 CNC Milling machine we do work for Nuclear Fuel Complex, Mishra Dhatu Nigam Limited
                        (MIDHANI),SECOBEC Pvt. Ltd., MEDHA Servo Drives Pvt. Ltd., POLMOR Steels Pvt. Ltd. etc.
                        <br />
                        <br />
                        Here is the list of Machines we use:
                    </p>
                    <div>
                        <ul className='list my-3 d-flex flex-column w-75'>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>CNC TURNING CENTER  ( SJ 500 XL JOBBER ELITE )</span>
                                    <span>- 01 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>CNC TURNING CENTER  ( CYNIC 170 T ) </span>
                                    <span>- 01 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>CNC TURNING CENTER  ( GT-2A-36 )   </span>
                                    <span>- 01 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>CNC MILLING MACHINE  ( VMC )  ( CALIBER 6045 ) </span>
                                    <span>- 01 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>SINGLE SPINDLE AUTOMATS  [ PMT ]  (A-25) M/C </span>
                                    <span>- 10 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>SINGLE SPINDLE AUTOMATS  [ PMT ]  (A-32) M/C </span>
                                    <span>- 01 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>HMT -  [ H-22*3.0Meter ]   LATHE M/C</span>
                                    <span>-01 No's</span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>LATHE MACHINE   ( 15” Centre Height, ABC-1200mm )  ( DECORA ) </span>
                                    <span>- 01 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>4.1/2’ LATHE MACHINE  [ SAMRAT ]</span>
                                    <span>- 01 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>MILLING MACHINE  [ MECHANICY ]  X-1000; Y-450; Z-500 </span>
                                    <span>- 01 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>POWER PRESS (20 Ton)  [ VIVEK ]</span>
                                    <span>- 01 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>POWER PRESS (10 Ton)  [VIVEK]   </span>
                                    <span>- 01 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>POLISHING MACHINE’S</span>
                                    <span>- 04 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>BAND SAW CUTTING MACHINE</span>
                                    <span>- 01 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>DRILLING MACHINE  ( 3/4” )</span>
                                    <span>- 02 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>DRILLING MACHINE ( 1/2” )  </span>
                                    <span>- 03 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>TAPPING MACHINES    ( Tapping size up 20.00mm can be done )</span>
                                    <span>- 04 No’s </span>
                                </div>
                                <hr />
                            </li>
                            <li className='pb-2 me-5'>
                                <div className='d-flex justify-content-between'>
                                    <span>BENCH GRINDING</span>
                                    <span>- 03 No’s </span>
                                </div>
                                <hr />
                            </li>
                        </ul>
                    </div>
                </div>


                <div className='d-flex flex-column gap-2 mt-5'>
                    <div className='d-flex flex-column mx-auto justify-content-evenly flex-wrap gap-4 px-2'>
                        <MachineCard
                            img={m2}
                            title={'TRAUB A-25'}
                            description={`Automatic lathe, Max. 4000 rpm, A 25
                            Diameter of work admitted 25.7 mm Max. turning length:
                            with flat cames 70 mm Number of tool cutters 4 Spindle
                            speeds: 550 rpm up to 4000 rpm Turret slide: number of
                            tools 4 Diameter of tool holding fix 19 m m Distance max. to
                            spindle 120 mm Voltage 50 Hz 3x 380 Volt Total power
                            required 2.4 HP Machine's weight about 700 kg Overall
                            dimensions machine: Length 1300 mm Width 600 mm
                            Height 1500 mm`}
                        />
                        <MachineCard
                            img={m1}
                            title={'TRAUB A-32'}
                            description={`Automatic lathe, Max. 4000 rpm, A 25
                                Diameter of work admitted 25.7 mm Max. turning length:
                                with flat cames 70 m m Number of tool cutters 4 Spindle
                                speeds: 550 rpm up to 4000 rpm Turret slide: number of
                                tools 4 Diameter of tool holding fix 19 m m Distance max. to
                                spindle 120 mm Voltage 50 Hz 3x 380 Volt Total power
                                required 2.4 HP Machine's weight about 700 kg Overall
                                dimensions machine: Length 1300 mm Width 600 mm
                                Height 1500 mm`}
                        />
                    </div>
                    <div className='d-flex align-items-center justify-content-center '>

                        <Carousel>
                            {machines.map(machine => {
                                return (
                                    <CarouselItem key={machine}>
                                        <div className='p-5 mx-lg-5 mx-3'>
                                            <MachineCard
                                                img={machine}
                                                title={''}
                                                description={''}
                                            />
                                        </div>
                                    </CarouselItem>
                                )
                            })}
                        </Carousel>

                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Machines