
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import CTA from "./CTA"
import { NavLink } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import companyLogo from '../assets/Yamani_logo.png'

function Navigation() {
    return (
        <Navbar fixed="top" expand="lg" className="bg-black p-2" bg='dark' data-bs-theme='dark'>
            <Container fluid>
                <NavLink to="/" className='nav-link d-flex align-items-start fw-bold fs-4 text-white'>
                    <img src={companyLogo} alt="logo" className='img-fluid' width={'44px'} />
                    <div className='text-start'>
                        YAMANI <br className='logo-break' /> Engineering Works
                    </div>
                </NavLink>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav
                        className="ms-4 me-auto my-2 my-lg-0 fw-bold"
                    >
                        <AnchorLink href="#home" className='nav-link' >
                            HOME
                        </AnchorLink>
                        <AnchorLink href="#products" className='nav-link' >
                            PRODUCTS
                        </AnchorLink>
                        <AnchorLink href="#about" className='nav-link' >
                            ABOUT
                        </AnchorLink>
                        <AnchorLink href="#contact" className='nav-link' >
                            CONTACT US
                        </AnchorLink>

                    </Nav>
                    <CTA />
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;


