import React from 'react'
import c1 from '../assets/Q-230203_YAMANI_ENGINEERING_WORKS.jpg'

const Certification = () => {
    return (<>
        <div className="my-5">
            <div>
                <h2 className="display-5 fw-bold">Certification</h2>
                <div className='mt-5 d-flex align-content-center justify-content-center gap-5'>
                    <img src={c1} alt="certificates" className='img-fluid' width={'50%'} />
                </div>
            </div>
        </div>
    </>)
}

export default Certification