import React from 'react'
import team1 from '../assets/team1.jpg'
import team2 from '../assets/team2.jpg'
import team3 from '../assets/team3.jpg'

const MyTeam = () => {
    return (<>
        <div id='myTeam' className='p-5'>
            <h3 className='mb-3 display-5 fw-bold'>Our Team</h3>
            <div className='d-flex flex-sm-row flex-column gap-5 my-5 mx-md-3 mx-1'>
                <div>
                    <img src={team1} alt="team1" className='img-fluid rounded-end' />
                </div>
                <div>
                    <img src={team2} alt="team2" className='img-fluid rounded' />
                </div>
                <div>
                    <img src={team3} alt="team3" className='img-fluid rounded-start' />
                </div>
            </div>
        </div>
    </>)
}

export default MyTeam