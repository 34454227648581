import React from 'react'
import '../styles/UtilStyles.css'
import CTA from './CTA'

const Hero = () => {
    return (<>

        <div id="home" className='mb-2 pt-md-5 pt-4'>

            <div className=" text-center bg-image homeCover">
                <div className="mask w-100 h-100 m-0 hero">
                    <div className="d-flex justify-content-center align-items-center h-100">

                        <div className='w-100'>
                            <div className="text-white text-start d-flex flex-column align-items-md-start ms-sm-5 align-items-center">
                                <h2 className="display-2 fw-bold text-center text-md-start">
                                    Built for Business,<br />  Built to Last
                                </h2>
                                <p className="fs-5 ms-3 ms-md-0 text-md-start text-center">
                                    Streamlined Efficiency,<br /> Unmatched Accuracy <br />Your Partner for Machining & Press Components
                                </p>
                                <div className='mt-3'>
                                    <CTA />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    </>)
}

export default Hero