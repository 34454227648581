import React from 'react'
import '../styles/UtilStyles.css';

const MachineCard = (props) => {
    return (
        <div className="card shadow">
            <div className='d-flex flex-sm-row flex-column align-items-center justify-content-center'>
                <div className=' w-75'>
                    <img src={props.img} className="card-img-top img-fluid p-3" alt="my machines" />
                </div>
                {props.title === '' ? <></> : (<>
                    <div className="card-body text-start w-100">
                        <h5 className="card-title fw-bold mb-3">{props.title}</h5>
                        <p className="card-text text-justify fs-6">{props.description}</p>
                    </div>
                </>)}
            </div>
        </div>
    )
}

export default MachineCard