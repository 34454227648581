import React from 'react'
import Navigation from './Navigation'

const Header = () => {
    return (<>
        {/* <div>Header</div> */}
        <div className=''>
            <Navigation />
        </div>
    </>)
}

export default Header