import React from 'react'
import contactus from '../assets/contactus.jpg'

const Contact = () => {
    return (<>
        <div id='contact' className='p-3 bglightblack text-white'>
            <div className='m-5'>
                <h2 className='display-5 fw-bold m-5'>Contact Us</h2>
                <div className='d-flex gap-3 flex-lg-row flex-column align-items-center justify-content-around'>
                    <div className=''>
                        <img src={contactus} alt={'location'} className='img-fluid rounded' width={'500px'} />
                    </div>
                    <div>
                        <div className='d-flex flex-column gap-3 align-items-center text-black'>
                            <div className='d-flex flex-column flex-sm-row gap-3'>
                                <div className='bg-light d-flex flex-column p-3 rounded'>
                                    <div><i className="fa-solid fa-envelope p-3 rounded-circle fs-1"></i></div>
                                    <p className='fw-bold'>EMAIL ADDRESS</p>
                                    <a className='nav-link text-danger fw-semibold' href='mailto:yew_hyd@yahoo.in'>yew_hyd@yahoo.in</a>
                                </div>
                                <div className='bg-light d-flex flex-column p-3 rounded'>
                                    <div><i className="fa-solid fa-phone p-3 rounded-circle fs-1"></i></div>
                                    <p className='fw-bold'>PHONE NUMBER</p>
                                    <a className='nav-link text-danger fw-semibold' href='tel:+91 9490190208'>+91 9490190208</a>
                                    <a className='nav-link text-danger fw-semibold' href='tel:+91 9849530027'>+91 9849530027</a>

                                </div>
                            </div>
                            <div className='bg-light d-flex flex-column p-3 rounded'>
                                <div><i className="fa-solid fa-location-dot p-3 rounded-circle fs-1"></i></div>
                                <p className='fw-bold'>TELANGANA</p>
                                <p className='fw-semibold text-muted'>YAMANI ENGINEERING WORKS <br />1-9-382/1/2B, PRAGATHI INDUSTRIAL <br />AREA, KUSHAIGUDA, HYDERABAD, <br />TELANGANA 500062</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Contact
