import React from 'react'

const Footer = () => {
    return (<>
        <div className='d-flex justify-content-center bg-black text-white align-items-center py-3'>
            <p>Copyright © 2024 | All rights reserved | Powered by a.</p>
        </div>
    </>)
}

export default Footer