import React from 'react'
import Hero from '../components/Hero'
import AboutUs from '../components/AboutUs'
import MyTeam from '../components/MyTeam'
import Machines from '../components/Machines'
import Gallery from '../components/Gallery'
import Contact from '../components/Contact'
// import Clients from '../components/Clients'
import Certification from '../components/Certification'

const Home = () => {

    return (<>
        <Hero />
        <Machines />
        <Gallery />
        <MyTeam />
        {/* <Clients /> */}
        <AboutUs />
        <Certification />
        <Contact />
    </>)
}

export default Home