import Carousel from 'react-bootstrap/Carousel';

import g1 from '../assets/gallery.jpg'
import g2 from '../assets/gallery2.jpg'
import g3 from '../assets/gallery3.jpg'
import g4 from '../assets/gallery4.jpg'
import g5 from '../assets/gallery5.jpg'
import g6 from '../assets/gallery6.jpg'
import g7 from '../assets/gallery7.jpg'
import { Fragment } from 'react';

function Gallery() {
    const products = [g1, g2, g3, g4, g5, g6, g7];

    return (<>
        <div id='products' className='bgblacksecondary text-white p-md-5 pt-3'>

            <div className='p-md-5'>
                <h2 className='display-5 fw-bold mb-5'>Products</h2>
                <Carousel data-bs-theme="dark">
                    {products.map((product, i) => {
                        return (
                            <Carousel.Item key={i} >
                                <div className=''>
                                    <img
                                        className="img-fluid w-100 rounded"
                                        src={product}
                                        alt="slide"
                                    />
                                </div>
                            </Carousel.Item>
                        )
                    })}

                </Carousel>
            </div>
        </div>
    </>);
}

export default Gallery;