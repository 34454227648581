import React from 'react'

const AboutUs = () => {
    return (<>
        <div id="about" className='pt-3'>
            <div className='bgblacksecondary text-white py-5'>
                <div className='w-75 mx-auto'>
                    <h2 className='fw-bold display-5 mb-3'>About Us</h2>
                    <div className='mb-5 ' style={{ color: '#aaa' }}>
                        <h5> Delivering Precision Components since 1998</h5>
                        <h5> Full-fledged manufacturing, qualified engineers, and unwavering commitment to quality.</h5>
                    </div>
                    <p className='text-start' style={{ color: "#ddd" }}>YAMANI Engineering Works, established in 1998, has proudly built a reputation for delivering high-quality, custom-engineered precision components to diverse industries. Our state-of-the-art manufacturing facilities in Hyderabad, India, equipped with CNC turning and MIlling - Traub A-32 and A-25 lathes, are backed by a team of qualified engineers and dedicated quality control systems. We collaborate closely with each client, from initial drawings to final delivery, ensuring every component meets the most stringent specifications and exceeds expectations. Whether you require intricate machined parts or robust press-formed components, Yamani Engineering Works is your trusted partner for unmatched accuracy, on-time delivery, and unwavering commitment to quality.</p>
                    <div className='d-flex flex-column align-items-start mt-4'>
                        <p className='fs-5'>Types of services we provide are :</p>
                        <ul className='d-flex flex-column align-items-start gap-1 '>
                            <li className='list-item'><span className='fs-6'>MANUFACTURING OF PRECISION MACHINING COMPONENTS</span></li>
                            <li className='list-item'><span className='fs-6'>MANUFACTURING OF PRECISION PRESS COMPONENTS</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default AboutUs